import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "../../../_services/auth.service";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {LogService} from "../../../_services/log.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
      private authS: AuthService,
      private logS: LogService,
  ) {
      this.logS.log_construct('service', 'AUTH GUARD');
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authS.getUser().pipe(
        map((user) => {
          if (user) {
            return true;
          } else {
            this.authS.logout();
            return false;
          }
        })
    );
  }
}
